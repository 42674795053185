// @flow
import React, { type Node } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box, Link, Typography,
} from "@mui/material";
import { makeStylesTyped } from "@fas/cpa-cabinet-ui/lib/helpers";

const useStyles = makeStylesTyped(() => ({
  footer: {
    textAlign: "center",
    width: "100%",
    padding: "10px",
    textAlign: "center",
  },
  copyright: {
    marginBottom: "10px",
  },
  policy: {
    margin: "0 20px",
  },
  terms: {
    margin: "0 20px",
  },
}));

function Footer(): Node {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();

  return (
    <Box className={classes.footer}>
      <Typography className={classes.copyright} variant="bodySmall">
        {`Copyright © Maverex Services Kft, ${currentYear}`}
      </Typography>
      <Box>
        <Link underline="none" variant="bodySmall" className={classes.policy} component={RouterLink} to="/privacy">Privacy Policy</Link>
        <Link underline="none" variant="bodySmall" className={classes.terms} component={RouterLink} to="/terms">Terms of Use</Link>
      </Box>
    </Box>
  );
}
export default Footer;
