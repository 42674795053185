// @flow
import React, { type StatelessFunctionalComponent, useState } from "react";
import {
  Box, Card, Container, Typography,
} from "@mui/material";
import { Navigate } from "react-router-dom";
import { makeStylesTyped } from "@fas/cpa-cabinet-ui/lib/helpers";
import { isAuthenticated, setAuthToken } from "../../services/request";
import AuthField from "./AuthField";
import SelectProductCompanyField from "./SelectProductCompanyField";
import { setAuthDataSaga } from "../../actions/auth";

type Props = {
  location: *,
  history: *,
  onLogin: typeof setAuthDataSaga,
}

const useStyles = makeStylesTyped(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "calc(100vh - 104px)",
    padding: "0",
  },
  card: {
    maxWidth: "360px",
    width: "100%",
    background: "transparent",
  },
  title: {
    color: "#fff",
    marginBottom: "40px",
  },
}));

const Login: StatelessFunctionalComponent<Props> = ({ location, history, onLogin }: Props) => {
  const classes = useStyles();
  const [loginData, setLoginData] = useState<{token: string, expires: number} | void>();

  if (isAuthenticated()) {
    const { from } = location.state || { from: { pathname: "/" } };
    return <Navigate to={from} />;
  }

  const handleSubmit = (companies: string[]) => {
    if (loginData) {
      const { from } = location.state || { from: { pathname: "/" } };
      onLogin({
        history,
        companies,
        redirect: from.pathname,
        expires: new Date(loginData.expires * 1000),
      });
    }
  };

  return (
    <Container component="main" className={classes.container}>
      <Card className={classes.card}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h2" className={classes.title}>
            Sign In
          </Typography>
          { !loginData ? (
            <AuthField onNext={(data: {expires: number, token: string}) => {
              setAuthToken(data.token, new Date(data.expires * 1000));
              setLoginData(data);
            }}
            />
          ) : (
            <SelectProductCompanyField onNext={handleSubmit} />
          )}
        </Box>
      </Card>
    </Container>
  );
};

export default Login;
